import React, { useEffect, useState } from "react";

import { TextField, InputAdornment, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import styled from "styled-components";

import { updateAppSettingsAPI } from "../api/updateAppSettingsAPI";
import { getAppSettingsAPI } from "../api/getAppSettingsAPI";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LogsTable } from "./LogsTable";


const ContentContainer = styled.div`
  margin: 30px 20px 20px 20px;
  padding: 10px;
`;
const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%,
  flex-direction: row;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  flex-direction: row;
  padding-block-start: 10px;
`;


const whiteColor = "#FFFFFF";

const settingsContainer = {
  marginBottom: "25px",
  marginTop: "25px",
}

const accordionContainer = {
  margin: "3px 20px 0px 20px",
};
const accordionSummary = {
  backgroundColor: "#4A4A4A",
};
const font = {
  fontSize: "16px",
  fontFamily: "Open Sans",
  fontStyle: "normal",
  letterSpacing: "0.6px",
  fontWeight: 400,
}
const accordionHeader = { 
  ...font,
  color: whiteColor,
};
const accordionDetails = {
  backgroundColor: "#EDEDED",
}
const accordionMessage = {
  ...font,
  margin: "20px 10px 0 10px",
};
const textFieldStyle = {
  marginRight: "40px",
  marginBottom: "20px",
};
const gpsTitle = {
  ...font,
  fontWeight: 600,
  margin: "20px 10px",
};


export const Settings = () => {
    const [unregisterTimer, setUnregisterTimer] = useState("");
    const [operationTimer, setOperationTimer] = useState("");
    const [confirmationTimer, setConfirmationTimer] = useState("");
    const [jobStatusUpdateTimer, setJobStatusUpdateTimer] = useState("");
    const [gpsAreaOfValidation, setGpsAreaOfValidation] = useState("");
    const [gpsValidationTimer, setGpsValidationTimer] = useState("");
    const [gpsLocationIntervalTimer, setGpsLocationIntervalTimer] = useState("");
    const [gpsNumberOfTimes, setGpsNumberOfTimes] = useState("");

  const getAppSettings = async () => {
    const { data } = await getAppSettingsAPI();
    const { 
      unregisterTimer,
      operationTimer,
      confirmationTimer,
      jobStatusUpdateTimer,
      gpsAreaOfValidation,
      gpsValidationTimer,
      gpsLocationIntervalTimer,
      gpsNumberOfTimes,
    } = data;
    console.log("data", data)
    setUnregisterTimer(unregisterTimer);
    setOperationTimer(operationTimer);
    setConfirmationTimer(confirmationTimer);
    setJobStatusUpdateTimer(jobStatusUpdateTimer);
    setGpsAreaOfValidation(gpsAreaOfValidation);
    setGpsValidationTimer(gpsValidationTimer);
    setGpsLocationIntervalTimer(gpsLocationIntervalTimer);
    setGpsNumberOfTimes(gpsNumberOfTimes);
  }
  useEffect(()=> {
    getAppSettings();
  }, [])

  const updateSettings = async () => {
    const appSettingsResponse = await updateAppSettingsAPI(
      unregisterTimer, operationTimer, confirmationTimer,
      jobStatusUpdateTimer, gpsAreaOfValidation, gpsValidationTimer,
      gpsLocationIntervalTimer, gpsNumberOfTimes);
    console.log("appSettingsResponse", appSettingsResponse);
  }
  return (<div style={settingsContainer}>
  <Accordion style={accordionContainer}>
    <AccordionSummary style={accordionSummary}
      expandIcon={<ExpandMoreIcon style={{ color: whiteColor }} />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <div style={accordionHeader}>
        Mobile application settings
      </div>
    </AccordionSummary>
    <AccordionDetails style={accordionDetails}>
      <Typography style={accordionMessage}>
        Please, pay attention once you apply changes for setting timers It will be used for all subsequent registrations in the mobile application.
      </Typography>
      <ContentContainer>
        <RowContainer>
          <TextField
            style={textFieldStyle}
            label="Unregister timer"
            id="unregister-timer"
            sx={{ m: 1, width: '25ch' }}
            InputProps={{
              endAdornment: <InputAdornment position="start"> min</InputAdornment>,
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            onChange={(e) => setUnregisterTimer(parseInt(e.target.value))}
            value={unregisterTimer}
            variant="standard"
            type="number"
            helperText="Driver will be automatically unregister."
          />
          <TextField
            style={textFieldStyle}
            label="Operation timer"
            id="operation-timer"
            sx={{ m: 1, width: '25ch' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">min</InputAdornment>,
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            onChange={(e) => setOperationTimer(parseInt(e.target.value))}
            value={operationTimer}
            variant="standard"
            type="number"
            helperText="Timeout occurs, and the driver will be able to call the operation desk."
          />
          <TextField
            style={textFieldStyle}
            label="Confirmation timer"
            id="confirmation-timer"
            sx={{ m: 1, width: '25ch' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">min</InputAdornment>,
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            onChange={(e) => setConfirmationTimer(parseInt(e.target.value))}
            value={confirmationTimer}
            variant="standard"
            type="number"
            helperText="Confirmation button will be shown for confirming driver location for following 'jobs'"
          />
          <TextField
            style={textFieldStyle}
            label="Job status update interval"
            id="job-status-update-interval"
            sx={{ m: 1, width: '25ch' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">sec</InputAdornment>,
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            onChange={(e) => setJobStatusUpdateTimer(parseInt(e.target.value))}
            value={jobStatusUpdateTimer}
            variant="standard"
            type="number"
            helperText="How often the job status is updated."
          />
        </RowContainer>
        <Typography style={gpsTitle}>
          GPS settings
        </Typography>

        <TextField
          style={{ marginBottom: "30px" }}
          label="Area of GPS validation"
          id="area-of-validation"
          sx={{ m: 1, width: '25ch' }}
          InputProps={{
            endAdornment: <InputAdornment position="start">m</InputAdornment>,
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          value={gpsAreaOfValidation}
          onChange={(e) => setGpsAreaOfValidation(parseInt(e.target.value))}
          variant="standard"
          type="number"
          helperText="Radius of GPS validation"
        />
        <RowContainer>
          <TextField
            style={textFieldStyle}
            label="Gps location interval"
            id="location-interval"
            sx={{ m: 1, width: '25ch' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">sec</InputAdornment>,
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            value={gpsLocationIntervalTimer}
            onChange={(e) => setGpsLocationIntervalTimer(parseInt(e.target.value))}
            variant="standard"
            type="number"
            helperText="How often the location is updated."
          />
          <TextField
            style={textFieldStyle}
            label="GPS Validation timer"
            id="validation-timer"
            sx={{ m: 1, width: '25ch' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">sec</InputAdornment>,
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            value={gpsValidationTimer}
            onChange={(e) => setGpsValidationTimer(parseInt(e.target.value))}
            variant="standard"
            type="number"
            helperText="How long the GPS location needs to be inside the validation area.In order to confirm the tranferpoint automatically."
          />
          <TextField
            style={textFieldStyle}
            label="Validation threshold"
            id="validation-threshold"
            sx={{ m: 1, width: '25ch' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">
                times out of {Math.floor(gpsValidationTimer/gpsLocationIntervalTimer)}
              </InputAdornment>,
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            value={gpsNumberOfTimes}
            onChange={(e) => setGpsNumberOfTimes(parseInt(e.target.value))}
            variant="standard"
            type="number"
            helperText="How many times the GPS location validation needs to be good.In order to confirm the tranferpoint automatically."
          />
        </RowContainer>
        <ButtonContainer>
          <Button 
            variant="contained"
            size="small"
            color="success"
            onClick={()=> {
              console.log("applied")
              updateSettings()
            }}
          >
            Apply
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </AccordionDetails>
    </Accordion> 
     <Accordion style={accordionContainer}> 
    <AccordionSummary style={accordionSummary}
      expandIcon={<ExpandMoreIcon style={{ color: whiteColor }} />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <div style={accordionHeader}>
        Logs
      </div>
    </AccordionSummary>
    <AccordionDetails>
      <LogsTable />
    </AccordionDetails>
  </Accordion> 
  </div>
  );
};