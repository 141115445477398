import React from 'react'

export const WeakSignalIcon = () => {
    return (<React.Fragment>
     <svg style={{ zIndex: 2 }} width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75 0H0.5C0.223437 0 0 0.223437 0 0.5V4.5C0 4.77656 0.223437 5 0.5 5H2.75C3.02656 5 3.25 4.77656 3.25 4.5V0.5C3.25 0.223437 3.02656 0 2.75 0Z" fill="#F71C1C"/>
        </svg>
        <svg style={{ zIndex: 1, marginLeft: '-4px', marginBottom: '8px' }} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.3125 5H7.5625V13.5H5.3125V5ZM10.125 0.5H12.375V13.5H10.125V0.5ZM0.5 9.5H2.75V13.5H0.5V9.5Z" stroke="#555555"/>
        </svg>
    </React.Fragment>
    )
}