import React, { useState } from "react";

import styled from "styled-components";
//import { MenuIcon } from './ui/MenuIcon'
//import { UserIcon } from './ui/UserIcon'
//import { useLocalStorage } from './useLocalStorage'
import Button from '@mui/material/Button';

import { Navigate } from "react-router-dom";

import logo from '../icons/yilport-holding-logo.png' 

const StyledColumn = styled.div`
  font-size: 0.875em;
  display: flex;
  align-items: center;
`;
const StyledHeader = styled.header`
  flex-wrap: wrap;
  background-color: #4A4A4A;
  min-height: 70px;
  //padding-left: 2rem;
  display: flex;
  justify-content: space-between;
  //position: fixed;
  z-index: 100;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 2.4rem;
`;
const StyledTitle = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 2rem;
`;


export const Header = () => {
  const [navigateTo, setNavigateTo] = useState(false);

  const handleLogOut = () => {
    console.log("removeItem")
    localStorage.setItem('logged-in', JSON.stringify(false));
    setNavigateTo(true);
  }

  if (navigateTo) {
    return <Navigate to="/auth" />;
  }
  //console.log("stor", JSON.parse(localStorage.getItem('logged-in')))
  return (
    <StyledHeader>
        <StyledColumn>
          <div style={{backgroundColor: "#FFFFFF", height: "100%", display: "flex", alignItems: "center", paddingInline: "35px", paddingBlock: "8px"}}>
            <img src={logo} style={{ height: "38px"}}/*style={nbStyle.logo}*/ alt="yilport-logo"/>
          </div>
          <StyledTitle>Truck Management</StyledTitle>
        </StyledColumn>
        <StyledColumn /*style={{ display: "flex", width: "100%", justifyContent: "flex-end"}}*/>
          <Button 
            style={{ /*display: "flex", alighItem: "flex-end",*/ margin: "8px", marginInline: "20px", borderRadius: "20px", paddingInline: "20px", backgroundColor: "#0A9CEB", textTransform: "none" }} 
            variant="contained" size="small" onClick={handleLogOut}>Sign out</Button>
        </StyledColumn>

      {/*<StyledColumn>{isAuthenticated && userName}</StyledColumn>*/}
        {/*<StyledColumn>
          <UserIcon />
        </StyledColumn> */}
    </StyledHeader>
  );
};
