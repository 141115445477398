import React from 'react'
import { GoodSignalIcon } from './ui/signals/GoodSignalIcon'
import { WeakSignalIcon } from './ui/signals/WeakSignalIcon'
import { PoorSignalIcon } from './ui/signals/PoorSignalIcon'
import { NoSignalIcon } from './ui/signals/NoSignalIcon'
//import { DisconnectedIcon } from './ui/signals/DisconnectedIcon'

export const GetSignal = (signal) => {
    const signalIcon = (signal === "HIGH") 
        ? <GoodSignalIcon /> 
        : (signal === "LOW") ? <WeakSignalIcon />
        : (signal === "AVERAGE") ? <PoorSignalIcon /> : <NoSignalIcon />
    return (
        <React.Fragment>
            {signalIcon}
            {` ${signal ?? "NO CONNECTION"}`}
        </React.Fragment>
    )
}