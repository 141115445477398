import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getLogsAPI } from '../api/getLogsAPI';
import { useNavigate } from "react-router-dom";

const columns = [
  { field: 'visitId', headerName: 'Visit Id', width: 100 },
  { field: 'truckId', headerName: 'Truck Id', width: 100 },
  { field: 'wiRefs', headerName: 'Job Id', width: 100 },
  { field: 'jobStatus', headerName: 'Job status', width: 100 },
  { field: 'jobLocation', headerName: 'Job location', width: 150 },
  {field: 'description', headerName: 'Description', width: 480 },
  {field: 'device', headerName: 'Device', width: 280 },
  {field: 'accuracy', headerName: 'Accuracy', width: 100 },
  {field: 'createdAt', headerName: 'Date', width: 180 },
  {field: 'errorMessage', headerName: 'Error message', width: 680 },
];

export const LogsTable = () => {
  const [mobileLogs, setMobileLogs] = useState({});
  const navigate = useNavigate();
  const rows = mobileLogs?.list?.map((record, index) => {
    return {
      id: index + 1,
      visitId: record.visitId,
      truckId: record.truckId,
      wiRefs: record.wiRefs,
      jobStatus: record.jobStatus,
      jobLocation: record.jobLocation,
      description: record.description,
      device: record.device,
      accuracy: record.accuracy ? parseInt(record.accuracy).toFixed(2) : "",
      createdAt: new Date(record.createdAt).toLocaleString(),
      errorMessage: record.errorMessage,
    }
})

  const getLogs = async () => {
    const data = await getLogsAPI();
    if (!data) {
      navigate("/auth");
    } else {
      setMobileLogs(data.data);
    }
  };

  useEffect(() => {
    const getLogsInterval = setInterval(() => {
      getLogs();
    }, 10 * 1000);

    setTimeout(() => {
      getLogs();
    }, 0);

    return () => {
      clearInterval(getLogsInterval);
    };
  }, []);

  return (
    <div style={{ height: 400, width: '100%' }}>
      {rows ? <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        pageSize={10}
        //rowsPerPageOptions={[5]}
        //checkboxSelection
      /> : <></>}
    </div>
  );
}