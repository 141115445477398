import axios from "axios";

import { monitoringUrl } from "./monitoringUrl";

export const getLogsAPI = async () => {
  const config = {
    headers: {
      "X-Terminal-ID": "gavle",
    },
  };

  try {
    const response = await axios.get(
      `${monitoringUrl}/management/getLogs`,
      config
    );
    return response;
  } catch (e) {
    console.log("getLogs error", e);
    return false;
  }
};