import React, { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Header } from './Header';
import { GetSignal } from './GetSignal';
import { getActiveJobsAPI } from '../api/getActiveJobsAPI';

import { Settings } from './Settings';

import styled from "styled-components";

import { StyledJobStatus } from './ui/StyledJobStatus';

const StyledTableHeadCell = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  //text-align: left;
`;

const StyledTableCell = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;
const StyledTitle = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  margin: 0 0 25px 20px;
`;

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F1F1F1',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export const TruckManagment = () => {
  const [activeJobs, setActiveJobs] = useState([]);

  const getActiveJobs = async () => {
    const { data } = await getActiveJobsAPI();
    setActiveJobs(data);
  };

  useEffect(() => {
    const getActiveJobsInterval = setInterval(() => {
      getActiveJobs();
    }, 10 * 1000);

    setTimeout(() => {
      getActiveJobs();
    }, 0);

    return () => {
      clearInterval(getActiveJobsInterval);
    };
  }, []);

  return (
  <>
  <Header />
  <Settings />
  <StyledTitle>Active trucks, Gävle terminal</StyledTitle>
    <TableContainer component={Paper} /*style={{ marginLeft: '2rem'}}*/>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <StyledTableHeadCell>
                Visit ID
              </StyledTableHeadCell>
            </TableCell>
            <TableCell>
              <StyledTableHeadCell>
                Truck ID
              </StyledTableHeadCell>
            </TableCell>
            <TableCell >
              <StyledTableHeadCell>
                Job ID
              </StyledTableHeadCell>
            </TableCell>
            <TableCell /*align="right"*/>
              <StyledTableHeadCell>
              Job description
              </StyledTableHeadCell>
            </TableCell>
            <TableCell /*align="right"*/>
              <StyledTableHeadCell>
                Job status
              </StyledTableHeadCell>
            </TableCell>
            <TableCell /*align="right"*/>
              <StyledTableHeadCell>
                Job location
              </StyledTableHeadCell>
            </TableCell>
            <TableCell /*align="right"*/>
              <StyledTableHeadCell>
                Last update
              </StyledTableHeadCell>
            </TableCell>
            <TableCell /*align="right"*/>
              <StyledTableHeadCell>
                Signal quality
              </StyledTableHeadCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeJobs?.list?.map((record) => (
            <StyledTableRow
              key={record.visitId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <StyledTableCell>
                  {record.visitId}
                </StyledTableCell>
              </TableCell>
              <TableCell align="right"><StyledTableCell>{record.truckId}</StyledTableCell></TableCell>
              <TableCell align="right"><StyledTableCell>{record.wiRefs}</StyledTableCell></TableCell>
              <TableCell align="right"><StyledTableCell>{record.jobStepDescription}</StyledTableCell></TableCell>
              <TableCell align="right"><StyledTableCell><StyledJobStatus jobStatus={record.jobStatus} /></StyledTableCell></TableCell>
              <TableCell align="right"><StyledTableCell>{record.jobLocation}</StyledTableCell></TableCell>
              <TableCell align="right"><StyledTableCell>{new Date(record.createdAt).toLocaleString()}</StyledTableCell></TableCell>
              <TableCell align="right"><StyledTableCell>{GetSignal(record.signalQuality)}</StyledTableCell></TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
   </>
  );
}