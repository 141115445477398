/*
export const activeJobs = () => {
    return {
        "list":[
            {"id":1,"api_request":"/monitoring/jobStatusUpdate?truckId=81&visitId=67&wiRefs=10&signalQuality=LOW&jobStatus=CANSELED&jobLocation=YARD.R2.01.25.04&activeTruck=true&terminal=Gavle","visitId":"68","truckId":"81","wiRefs":"10","jobStatus":"CANCELED","jobLocation":"YARD.R2.01.25.04","activeTruck":true,"terminal":"Gavle","jobStepDescription":"#1 Container will be received from the truck","signalQuality":"HIGH","errorMessage":null,"description":null,"createdAt":"2022-10-23T14:48:42.700Z","updatedAt":"2022-10-23T14:48:42.700Z"},
            {"id":2,"api_request":"/monitoring/jobStatusUpdate?truckId=21&visitId=67&wiRefs=89&signalQuality=LOW&jobStatus=REJECTED&jobLocation=YARD.R1.01.07.04&activeTruck=true&terminal=Gavle","visitId":"67","truckId":"21","wiRefs":"89","jobStatus":"PLANNED","jobLocation":"YARD.R1.01.07.04","activeTruck":true,"terminal":"Gavle","jobStepDescription":"#1 Container will be delivered to the truck","signalQuality":"LOW","errorMessage":null,"description":null,"createdAt":"2022-10-23T14:49:59.696Z","updatedAt":"2022-10-23T14:49:59.696Z"},
            {"id":3,"api_request":"/monitoring/jobStatusUpdate?truckId=T1&visitId=1&wiRefs=1&signalQuality=LOW&jobStatus=PLANNED&jobLocation=YARD.R1.01.07.04&activeTruck=true&terminal=Gavle","visitId":"1","truckId":"T1","wiRefs":"1","jobStatus":"REJECTED","jobLocation":"YARD.R1.01.07.04","activeTruck":true,"terminal":"Gavle","jobStepDescription":"#1 Container will be delivered to the truck","signalQuality":"NO CONNECTION","errorMessage":null,"description":null,"createdAt":"2022-10-23T19:17:20.897Z","updatedAt":"2022-10-23T19:17:20.897Z"},
            {"id":7,"api_request":"/monitoring/jobStatusUpdate?truckId=T3&visitId=3&wiRefs=3&signalQuality=HIGH&jobStatus=WORKING&jobLocation=YARD.R4.01.11.04&activeTruck=true&terminal=Gavle","visitId":"3","truckId":"T3","wiRefs":"3","jobStatus":"WORKING","jobLocation":"YARD.R4.01.11.04","activeTruck":true,"terminal":"Gavle","jobStepDescription":"#1 Container will be delivered to the truck","signalQuality":"AVERAGE","errorMessage":null,"description":null,"createdAt":"2022-10-23T19:22:35.736Z","updatedAt":"2022-10-23T19:22:35.736Z"}
        ]}
}
*/

import axios from "axios";

import { monitoringUrl } from "./monitoringUrl";

export const getActiveJobsAPI = async (
 // terminal,
) => {
  const config = {
    headers: {
      "X-Terminal-ID": "gavle"/*terminal*/,
    },
  };

  try {
    const response = await axios.get(
      `${monitoringUrl}/management/getActiveJobs`,
      config
    );
    return response;
  } catch (e) {
    console.log("getActiveJobs error", e);
    return false;
  }
};