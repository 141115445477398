import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth } from "./components/Auth";
import { TruckManagment } from './components/TruckManagementTable';

function App() {
  const loggedIn = JSON.parse(localStorage.getItem('logged-in'));
  //console.log("loggedIn", loggedIn)
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={ loggedIn ? <TruckManagment /> : <Auth />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/management" element={<TruckManagment />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App