import React from "react";

const getJobStatusColor = (jobStatus) => {
  if (jobStatus === "PLANNED") return "#0A9CEB";
  if (jobStatus === "WORKING") return "#26B616";
  if (jobStatus === "REJECTED") return "#FE800B";
  if (jobStatus === "CANCELED") return "#E22020";
  return "#4F4F4F"
}

export const StyledJobStatus = ({ jobStatus }) => {
  return (
    <div 
      style={{fontWeight: 600, borderRadius: "20px", opacity: 0.8,
        color: "#FFFFFF", fontFamily: "Open Sans", letterSpacing: "0.4px",
        backgroundColor: getJobStatusColor(jobStatus), alignSelf: "center", textAlign: "center", paddingBlock: "5px", paddingInline: "10px"}}>
      {jobStatus}
    </div>
  );
};
