import axios from "axios";

import { monitoringUrl } from "./monitoringUrl";

export const updateAppSettingsAPI = async (
    unregisterTimer,
    operationTimer,
    confirmationTimer,
    jobStatusUpdateTimer,
    gpsAreaOfValidation,
    gpsValidationTimer,
    gpsLocationIntervalTimer,
    gpsNumberOfTimes,
    lastUpdated,
) => {
  const body = {
    unregisterTimer: unregisterTimer,
    operationTimer: operationTimer,
    confirmationTimer: confirmationTimer,
    jobStatusUpdateTimer: jobStatusUpdateTimer,
    gpsAreaOfValidation: gpsAreaOfValidation,
    gpsValidationTimer: gpsValidationTimer,
    gpsLocationIntervalTimer: gpsLocationIntervalTimer ?? 3,
    gpsNumberOfTimes: gpsNumberOfTimes ?? 7,
    lastUpdated: lastUpdated ?? "usrer1/desktop",
  };
  console.log("body", body)
  const config = {
    headers: {
      "X-Terminal-ID": "gavle",
    },
    params: {
      ...body,
    }
  };
  console.log("config", config)
  try {
    const response = await axios.post(
      `${monitoringUrl}/management/updateAppSettings`,
      null,
      config
    );
    return response;
  } catch (error) {
    console.log("udateAppSettingsAPI error", error);
  }
};
