import React from 'react'

export const PoorSignalIcon = () => {
    return (<React.Fragment>
        <svg style={{zIndex: 10}} width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5625 0.5H5.3125C5.03594 0.5 4.8125 0.723437 4.8125 1V9.5C4.8125 9.77656 5.03594 10 5.3125 10H7.5625C7.83906 10 8.0625 9.77656 8.0625 9.5V1C8.0625 0.723437 7.83906 0.5 7.5625 0.5ZM2.75 5H0.5C0.223437 5 0 5.22344 0 5.5V9.5C0 9.77656 0.223437 10 0.5 10H2.75C3.02656 10 3.25 9.77656 3.25 9.5V5.5C3.25 5.22344 3.02656 5 2.75 5Z" fill="#FADB14"/>
        </svg>
        <svg style={{marginLeft: '-9px', zIndex: 1, marginBottom: '4px'}} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.3125 5H7.5625V13.5H5.3125V5ZM10.125 0.5H12.375V13.5H10.125V0.5ZM0.5 9.5H2.75V13.5H0.5V9.5Z" stroke="#555555"/>
        </svg>
    </React.Fragment>
    )
}