import axios from "axios";

import { monitoringUrl } from "./monitoringUrl";

export const getAppSettingsAPI = async (
  //terminal
) => {
  const config = {
    headers: {
      "X-Terminal-ID": "Gavle",
    },
  };
  try {
    const response = await axios.get(
      `${monitoringUrl}/management/getAppSettings`, //?terminal=${terminal}
      config,
    );
    return response;
  } catch (e) {
    console.log("getAppSettings error", e);
    return false;
  }
};