import React, { useState } from "react";
import { monitoringUrl } from "../api/monitoringUrl";

export const Auth = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" method="POST" action={`${monitoringUrl}/management/authorization`}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Username</label>
            <input
              type="username"
              name="username"
              className="form-control mt-1"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              name="password"
              className="form-control mt-1"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <input type="hidden" name="terminal" value="Gavle"/>
            <input type="hidden" name="redirectUrl" value="/management"/>
            <input type="submit" style={{backgroundColor: "#25AAE1", marginTop: "8px"}}
                className="btn btn-primary" value="Sign In"/>
          </div>
        </div>
      </form>
    </div>
  )
}